import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 7.067vw;
  margin-right: 1.667vw;
  margin-bottom: 0.5vw;
`;

export const Header = styled.div`
  margin: ${(props) => props.margin || '3.1vw 0 1.5vw 0'};
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-weight: 600;
    font-size: 1.667vw;
    font-family: 'Poppins';
  }
`;
