import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

export const TableComponent = styled(Table)`
  padding: ${(props) => props.padding || 0};
`;

export const Header = styled(TableRow)`
  background-color: ${(props) => props.theme.colors.tableHeader};
  .MuiTableCell-head {
    color: ${(props) => props.theme.colors.tableHeaderText} !important;
    font-family: Poppins;
    font-size: 0.972vw;
    font-style: normal;
    font-weight: 600;
    line-height: 1.458vw;
    letter-spacing: 0.1px;
  }
`;

export const Collum = styled(TableCell)`
  font-family: Poppins;
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.458vw;
  letter-spacing: 0.1px;
  width: ${(props) => props.width || 'auto'};
  max-width: ${(props) => props.width || 'initial'};
  word-wrap: break-word;
`;

export const Container = styled(TableContainer)`
  width: ${(props) => props.width || 'auto'};
  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  max-height: ${(props) => props.maxheight || 'auto'};
  ::-webkit-scrollbar {
    width: 0.8vw;
  }
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.tableScrollBackground};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.tableScroll};
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
`;

export const Row = styled(TableRow)`
  :hover {
    cursor: ${(props) => props.cursor};
    background-color: ${(props) => props.theme.colors.tableHoverRow};
  }
`;
