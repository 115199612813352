import styled from 'styled-components';

export const ContainerLegalRepresentative = styled.div`
  margin-top: 50px;
  font-family: Poppins;
  font-style: normal;

  & > h2,
  h4 {
    margin: 0.764vw 0;
    font-weight: 600;
    font-size: 1.389vw;
  }

  & > div {
    width: 80rem;
    border-radius: 10px;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 10px;

    & h4 {
      margin: 20px 0 0 20px;
      font-size: 18px;
    }

    & .card {
      border-radius: 0.8rem;
    }
  }

  .box-legal-representative-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  }

  .box-legal-representative-data-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    .MuiRadio-colorSecondary.Mui-checked {
      color: ${(props) => props.theme.colors.brown} !important;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 16px;
    padding: 10px;
    font-weight: bold;
  }

  .box-legal-representative-data > p {
    font-size: 1.111vw;
  }

  .box-legal-representative-title-data {
    text-transform: uppercase;
    font-weight: bolder;
  }

  .box-legal-representative-actions {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: 30px 20px 10px;
  }
`;

export const ContainerAccordion = styled.div`
  padding: 1rem 0 0 0;
`;

export const TitleAccordion = styled.div`
  display: flex;
`;

export const titleDocs = styled.div`
  padding-top: 2rem;

  & h3 {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const Section = styled.div`
  display: flex;
`;

export const ContainerSelect = styled.div`
  width: 26.306vw;
  margin: 1.389vw 0 0 1.389vw;

  h4 {
    font-family: Poppins;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.667vw;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const LoadingContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  color: ${(props) => props.theme.colors.loader};
`;

export const InformationContainerRepresentatives = styled.div`
  width: 85%;
  padding: 0 1vw 0 0;
  h3 {
    font-family: Poppins;
    font-size: 1.111vw;
    font-style: normal;
    font-weight: 600;
    line-height: 1.667vw;
    letter-spacing: 1px;
    text-align: left;
    color: ${(props) => props.theme.colors.darkGrayishBlue};
  }
`;
