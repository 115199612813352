import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin:0;
    padding:0;
    font-family: Poppins, sans-serif;
    font-size: 1vw;

  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .swal-text-loading {
    grid-template-columns: auto !important;
  }

  .swal2-popup.swal2-loading{
      grid-template-columns: auto auto;
      width: 500px;
  }
  //z-index setado para ficar acima de todos os modais da aplicação
  .swal2-container{
    z-index:2000;
  }
  .swal2-loader{ border-color:${(props) => props.theme.colors.loader} transparent ${(props) =>
  props.theme.colors.loader} transparent}

  .swal2-icon.swal2-warning {
    border-color: ${(props) => props.theme.colors.nutBrown};
    color: ${(props) => props.theme.colors.nutBrown};
}

  .swal2-cancel.swal2-styled {
    background-color: ${(props) => props.theme.colors.buttonBack} !important;
    width: 12.153vw;
    height: 3.333vw;
    font-size: 0.972vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.667vw;
    letter-spacing: 0.09vw;
    text-align: center;
    color: ${(props) => props.theme.colors.buttonBackText} !important;
    box-sizing: border-box;
    border-radius: 4px;

    :hover{
      background-color: ${(props) => props.theme.colors.buttonBackHover} !important;
      color: ${(props) => props.theme.colors.buttonBackHoverText} !important;   
    }
  }
  .swal2-styled.swal2-confirm,.swal2-deny.swal2-styled  {
    background-color:  ${(props) => props.theme.colors.buttonConfirm} !important;
    width: 12.153vw;
    height: 3.333vw;
    font-size: 0.972vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.667vw;
    letter-spacing: 0.09vw;
    text-align: center;
    color: ${(props) => props.theme.colors.buttonConfirmText} !important;
    box-sizing: border-box;
    border-radius: 4px;

    :hover{
      background-color: ${(props) => props.theme.colors.buttonConfirmHover} !important;
      color: ${(props) => props.theme.colors.buttonConfirmHoverText} !important;    
    }
  }

  button > svg.default-svg{
    color: ${(props) => props.theme.colors.white};
  }

  button > span > svg.default-svg{
    margin-right:1vw;
  }

  button > svg.default-svg:hover {
    color: ${(props) => props.theme.colors.black};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.gray};
    border-width: 1px;
  }

  div.MuiListItem-root.Mui-selected,
.MuiListItem-gutters .MuiListItem-root.Mui-selected:hover {
  border-left: 0.4vw solid ${(props) => props.theme.colors.menuSelectItenBorder};
  border-radius: 0px 4px 4px 0px;
  padding-left: 1.7569546120058566vw;
  background-color: ${(props) => props.theme.colors.menuSelectIten};
}

`;
