import styled from 'styled-components';

export const LoadingContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  color: ${(props) => props.theme.colors.loader};
`;

export const SmallerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1,
  h2,
  h4,
  h5 {
    margin: 0.5vw;
  }

  h3 {
    margin: 3vw 0 0.5vw 0;
  }
  h4 {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3vw 0;
  width: 28.572vw;
`;

export const SectionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3vw 0;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 1vw;
  flex-direction: column;
  text-transform: uppercase;
`;
export const TitleOptions = styled.h2`
  font-family: Poppins;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.667vw;
  letter-spacing: 0em;
  text-align: left;
`;

export const SectionTitle = styled.span`
  margin-top: 0.764vw;
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  font-size: 1.389vw;
  line-height: 2.083vw;
`;

export const OptionsContainerProfiles = styled(OptionsContainer)`
  margin-left: 0;
  margin-right: 1.389vw;
`;
