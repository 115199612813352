import api from '../../utils/services/api';
import removeSpecialChars from '../../utils/functions/removeSpecialChars';
import { showAlert } from '../ducks/sweetAlert';

import {
  transfersActionError,
  transfersActionLoading,
  transfersActionSuccess,
  transfersIDActionError,
  transfersIDActionLoading,
  transfersIDActionSuccess,
  transfersTedActionLoading,
  transfersTedctionSuccess,
  transfersTedctionError,
} from '../ducks/transfers';

export const transfersFetchAction = ({
  LIMIT = 10,
  PAGE = 1,
  TIPO = '0',
  TIPO_TRANSACAO = '0',
  STATUS = '0',
  SEARCH = '',
  DATA_FINAL = '',
  DATA_INICIAL = '',
}) => {
  return async (dispatch) => {
    dispatch(transfersActionLoading());
    try {
      const response = await api.get(
        `/transferencias?PAGE=${PAGE}&LIMIT=${LIMIT}&DATA_INICIO=${DATA_INICIAL ?? ''}&DATA_FIM=${
          DATA_FINAL ?? ''
        }&TIPO=${TIPO}&TIPO_TRANSACAO=${TIPO_TRANSACAO}&STATUS=${STATUS}&SEARCH=${removeSpecialChars(
          ['.', '-', '/'],
          SEARCH,
        )}`,
      );
      dispatch(transfersActionSuccess(response.data));
    } catch (error) {
      dispatch(transfersActionError());
    }
  };
};

export const transfersIDFetchAction = (ID, callback = () => undefined) => {
  return async (dispatch) => {
    dispatch(transfersTedActionLoading());

    try {
      const response = await api.get(`transacao/listar/detalhes/saque?ID=${ID}`);
      dispatch(transfersTedctionSuccess(response));
      callback(response);
    } catch (error) {
      dispatch(transfersTedctionError());
      callback(error);
    }
  };
};

export const tedAproved = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(transfersIDActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(transfersFetchAction({ PAGE: 1, LIMIT: 10, TIPO: 'Ted' }));
    };
    try {
      const response = await api.post(`ted/aprovar`, params);
      dispatch(transfersIDActionSuccess(response));
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'TED enviada para C&M',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(transfersIDActionError());
      callback(error);

      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel aprovar a TED',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const tedNegaded = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(transfersIDActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(transfersFetchAction({ PAGE: 1, LIMIT: 10, TIPO: 'Ted' }));
    };
    try {
      const response = await api.post(`ted/recusar`, params);
      dispatch(transfersIDActionSuccess(response));
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'TED Negada',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(transfersIDActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel negar a TED',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const tedRejected = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(transfersIDActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(transfersFetchAction({ PAGE: 1, LIMIT: 10, TIPO: 'Ted' }));
    };

    try {
      const response = await api.post(`/ted/devolver`, params);
      dispatch(transfersIDActionSuccess(response));
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'TED reenviada para C&M',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(transfersIDActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel reenviar a TED',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
