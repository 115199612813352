import styled, { css } from 'styled-components';

export const status = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  width: max-content;
  border-radius: 4px;
  padding-left: 0.4rem;
  align-items: center;
`;

export const textStatus = styled.div`
  display: flex;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  text-transform: capitalize;
  padding: 0 0.4rem 0 0.4rem;
  color: ${(props) => (props.colors ? props.colors : props.theme.colors.vividRed_2)};
`;
